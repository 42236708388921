function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useDeviceInfo } from '@hooks/react-use';
import { SectionTitle } from './SectionTitle';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface SectionProps {
  title?: string;
  showTitleInH1?: boolean;
  subTitle?: string;
  subTitleLink?: string;
  sectionBeforeHighlighted?: string;
  source_component_data?: {
    [id: string]: string | number;
  };
  onClick?: () => void;
  children: React.ReactNode;
}
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1l5jv4y",
  styles: "width:100%;@media (min-width: 1024px){border-bottom-width:1px;--tw-border-opacity:0.1;border-color:rgb(255 255 255 / var(--tw-border-opacity));padding-bottom:1.5rem;}"
} : {
  name: "1nezzmu-Section",
  styles: "width:100%;@media (min-width: 1024px){border-bottom-width:1px;--tw-border-opacity:0.1;border-color:rgb(255 255 255 / var(--tw-border-opacity));padding-bottom:1.5rem;};label:Section;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const Section = ({
  title,
  showTitleInH1 = false,
  subTitle,
  subTitleLink = '',
  source_component_data,
  sectionBeforeHighlighted,
  children
}: SectionProps) => {
  const {
    screen_isDesktop
  } = useDeviceInfo();
  return _jsxs("div", {
    css: _ref,
    style: {
      borderBottomWidth: sectionBeforeHighlighted != title && screen_isDesktop ? '1px' : '0px'
    },
    children: [title || title && subTitle ? _jsx(SectionTitle, {
      title: title,
      showTitleInH1: showTitleInH1,
      subTitle: subTitle,
      subTitleLink: (subTitleLink as string),
      source_component_data: source_component_data
    }) : null, children]
  });
};